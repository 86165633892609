
@font-face {
  font-family: 'odia';
  font-style: normal;
  font-weight: normal;
  src: url('./odia.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'odia', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(246, 248, 252);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

* * * * *{
  font-family: 'odia', sans-serif;
}